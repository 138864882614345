import React, { useState, useEffect } from "react";
import { getAdminAuth, getLogs, getLogsFromStartEnd, getSystemPromptApi, updateSystemPromptApi } from "../../api"; // Adjust the import path as necessary
import styles from "./Administrator.module.css";
import { DetailsList, Dropdown, IDropdownOption, Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { ArrowCounterclockwise20Filled, ArrowEnter20Filled, ArrowEnterLeft20Filled, ArrowDownload20Filled, SignOut20Filled, SaveRegular } from "@fluentui/react-icons";
import { formatISO, subDays, subHours } from "date-fns";
import { UserChatMessage } from "../../components/UserChatMessage";
import { ChatLogContainer } from "../../components/ChatLogContainer";
import Skeleton from "react-loading-skeleton";

// Define the type for log entries
export interface LogEntry {
    chat_output: string;
    id: number;
    model: string;
    system_prompt: string;
    timestamp: string;
    user_input: string;
}

const Administrator = () => {
    const [systemPrompt, setSystemPrompt] = useState("");
    const [idToken, setIdToken] = useState<string | undefined>(); // You might be getting this from elsewhere
    const [startTime, setStarttime] = useState(formatISO(new Date(1753, 0, 1)));
    const [endTime, setEndtime] = useState(formatISO(new Date(9999, 11, 1)));
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [filteredLogs, setFilteredLogs] = useState<LogEntry[]>([]);
    const [selectedLogContent, setSelectedLogContent] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    
    const [error, setError] = useState("");
    const [clientSecret, setClientSecret] = useState(sessionStorage.getItem("clientSecret") ?? "");

    const [loadingLog, setLoadingLog] = useState(false);
    const [loadingPrompt, setLoadingPrompt] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);

    const isAuth = () => {
        return !(!clientSecret || clientSecret.trim() === "");
    }

    const logout = () => {
        sessionStorage.setItem("clientSecret", "")
        setClientSecret("")
    }

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const password = formData.get("password") as string;

        try {

            getAdminAuth(password, idToken)
                .then((response) => {
                    if(response.ok){
                        setClientSecret(password)
                        sessionStorage.setItem("clientSecret", password)
                    } else {
                        setClientSecret("")
                        sessionStorage.setItem("clientSecret", "")
                    }
                })
                .catch(() => setError("Unable to log in"))
            
        } catch (error) {
            setError("Unable to log in")
            setClientSecret("")
        } finally {
            
        }
    
      };

    const fetchSystemPrompt = async () => {
        setLoadingPrompt(true);
        try {
            const response = await getSystemPromptApi(clientSecret, systemPrompt, idToken);
            const data = await response.json();
            setSystemPrompt(data.prompt); // Adjust based on the actual response structure
        } catch (error) {
            console.error("Failed to fetch system prompt", error);
        } finally {
            setLoadingPrompt(false);
        }
    };

    const downloadLogs = async (type : string) => {

        setLoadingLog(true);
        try {
            
            console.log("start downloading logs");
            const response = await getLogs(clientSecret, idToken, type);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a")
            a.href = url;
            a.download = "logs."+type;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

        } catch (error) {
            console.error("Failed to fetch data logs", error);
        } finally {
            setLoadingLog(false);
        }

    };

    const downloadLogsCSV = async () => {
        downloadLogs("csv")
    };

    const downloadLogsJson = async () => {
        downloadLogs("json")
    };

    const fetchLog = async () => {
        setLoadingLog(true);
        try {
            console.log("start fetching logs");
            const response = await getLogsFromStartEnd(clientSecret, startTime, endTime, idToken);
            const data: LogEntry[] = await response.json();
            setLogs(data);
            setFilteredLogs(data);
        } catch (error) {
            console.error("Failed to fetch data logs", error);
        } finally {
            setLoadingLog(false);
        }
    };

    const handleUpdate = async () => {
        try {
            const newSystemPrompt = systemPrompt;
            const response = await updateSystemPromptApi(clientSecret, newSystemPrompt, idToken);
            alert("System prompt updated successfully");
        } catch (error) {
            console.error("Error updating system prompt", error);
        }
    };

    const onSystemPromptChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setSystemPrompt(systemPrompt);
        } else if (newValue.length <= 100000) {
            setSystemPrompt(newValue);
        }
    };

    const onLogClick = async () => {
        console.log("On log clicked");
    };

    const filterLogs = () => {
        const start = new Date(startTime).getTime();
        const end = new Date(endTime).getTime();
        const filtered = logs.filter(log => {
            const logTime = new Date(log.timestamp).getTime();
            return logTime >= start && logTime <= end;
        });
        setFilteredLogs(filtered);
    };

    
    useEffect(() => {

        if(isAuth()){
            // Fetch the current prompt when the component mounts
            fetchSystemPrompt();
            fetchLog();
        }
        
    }, [startTime, endTime, clientSecret]);

    if(isAuth()){

        return (
            <>
            <div className={styles.AdminContainer}>
                <div>
                    <h2>Anpassen der System Prompt</h2>
                    {loadingPrompt ? (
                        <div>
                            <Skeleton count={2} />
                        </div>
                    ) : (
                        <Stack verticalAlign="start" className={styles.systemPromptInputContainer}>
                            <TextField styles={{ field: {height: 1000}}} className={styles.systemPromptTextArea} multiline value={systemPrompt} onChange={onSystemPromptChange}/>
                            <Button
                                className={styles.AdminButton}
                                size="large"
                                icon={<SaveRegular primaryFill="rgba(115, 118, 225, 1)" />}
                                onClick={handleUpdate}
                                title="Update System Prompt"
                            >
                                Update System Prompt
                            </Button>
                        </Stack>
                    )}
                </div>
                <div>
                    <h2>Chat Logs</h2>
                    <Button size="large" className={styles.AdminButton} icon={<ArrowCounterclockwise20Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={fetchLog} title="Refresh Logs">
                        Refresh Logs
                    </Button>
                    <ChatLogContainer filteredLogs={filteredLogs} loading={loadingLog} logsPerPage={10} length={filteredLogs.length} />
                    <Button size="large" className={styles.AdminButton} icon={<ArrowDownload20Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={downloadLogsCSV} title="Logout">
                        Download Logs (CSV)
                    </Button>
                    <Button size="large" className={styles.AdminButton} icon={<ArrowDownload20Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={downloadLogsJson} title="Logout">
                        Download Logs (JSON)
                    </Button>
                    <Button size="large" className={styles.AdminButton} icon={<SignOut20Filled primaryFill="rgba(115, 118, 225, 1)" />} onClick={logout} title="Logout">
                        Logout
                    </Button>
                </div>
            </div>
            </>
        );

    } else {

        return (
            <>
            <div className={styles.AdminContainer}>
            <h2>Admin Login</h2>
            <form onSubmit={handleLogin}>
                <input type="password" name="password" placeholder="Password" required />
                <button type="submit">Login</button>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            </>
        );

    }
    
};

export default Administrator;
