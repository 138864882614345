import { Outlet, NavLink, Link } from "react-router-dom";
import React, { useState } from "react";
import { IS_PUBLIC } from "../../api/secret"
import { getAuth } from "../../api"; // Adjust the import path as necessary
import styles from "./Layout.module.css";

const Layout = () => {

    const [error, setError] = useState("");
    const [userClientSecret, setUserClientSecret] = useState(sessionStorage.getItem("userClientSecret") ?? "");
    const [idToken, setIdToken] = useState<string | undefined>(); // You might be getting this from elsewhere

    const isAuth = () => {
        return IS_PUBLIC || !(!userClientSecret || userClientSecret.trim() === "");
    }

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const password = formData.get("password") as string;

        try {
        
            getAuth(password, idToken, password)
                .then((response) => {
                    if(response.ok){
                        setUserClientSecret("secure")
                        sessionStorage.setItem("userClientSecret", "secure")
                    } else {
                        setUserClientSecret("")
                        sessionStorage.setItem("userClientSecret", "")
                    }
                })
                .catch(() => setError("Unable to log in"))
            
        } catch (error) {
            setError("Unable to log in")
            setUserClientSecret("")
        } finally {
            
        }

    }

    if(isAuth()){
        return (
            <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <img src="/assets/smc_logo.png" alt="SMC Logo" className={styles.logo} />
                            <h3 className={styles.headerTitle}>Meta Business Twin</h3>
                        </Link>
                        <nav>
                            <ul className={styles.headerNavList}>
                            </ul>
                        </nav>
                    </div>
                </header>

                <Outlet />

                <div className={styles.Imprint}>
                        <span><a target="_blank" href="https://www.schopf-meta-consult.de/deutsch/impressum/">Impressum</a> - <a target="_blank" href="https://www.schopf-meta-consult.de/deutsch/datenschutzerklarung/">Datenschutz</a></span>
                </div>

            </div>
        );
    } else {
        return (
        <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <img src="/assets/smc_logo.png" alt="SMC Logo" className={styles.logo} />
                            <h3 className={styles.headerTitle}>Meta Business Twin</h3>
                        </Link>
                        <nav>
                            <ul className={styles.headerNavList}>
                            </ul>
                        </nav>
                    </div>
                </header>

                <div className={styles.LoginContainer}>
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <input type="password" name="password" placeholder="Password" required />
                    <button type="submit">Login</button>
                </form>
                {error && <p style={{ color: "red" }}>{error}</p>}
                </div>

                <div className={styles.Imprint}>
                        <span><a target="_blank" href="https://www.schopf-meta-consult.de/deutsch/impressum/">Impressum</a> - <a target="_blank" href="https://www.schopf-meta-consult.de/deutsch/datenschutzerklarung/">Datenschutz</a></span>
                </div>

            </div>
        );

    }

};

export default Layout;
