import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "../../api"; // Adjust the import path as necessary

const AgentD = () => {

    const agentContainerRef = useRef(null);

    useEffect(() => {
        // Load the D-ID Agent script dynamically
        const script = document.createElement("script");
        script.type = "module";
        script.src = "https://agent.d-id.com/v1/index.js";
        script.dataset.name = "did-agent";
        script.dataset.mode = "fabio"; // Optional: Change mode if needed
        script.dataset.clientKey = "YXV0aDB8NjUzM2EyYTVkZTA0MWM4ZDQ0ZjQ0ZTY4OkpWaFY4WFAwY3FwcUJqZl9hQjR2Rw==";
        script.dataset.agentId = "agt_RldFsM8U";
        script.dataset.monitor = "true";
        document.body.appendChild(script);

    }, []);

    return (
        <div
            ref={agentContainerRef}
            style={{
                width: "400px", // Adjust as needed
                height: "600px",
                position: "relative",
                border: "2px solid black",
            }}
        />
    );

    
};

export default AgentD;
