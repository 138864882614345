import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./VideoPlayback.module.css"; // Adjust the import path as needed
import { useCookies } from "react-cookie";
import ChatAvatar from "../../components/ChatAvatar/ChatAvatar";
import { AirplaneTakeOff24Regular, Play24Filled } from "@fluentui/react-icons";
import { SHOW_VIDEOS } from "../../api/secret"
import { VIDEO_INTRO, VIDEO_REVISIT } from "./VideoExt";

const BackgroundVideo = () => {
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef = useRef();
    const exampleDivRef = useRef<HTMLDivElement>(null);
    const videoRootDiv = useRef<HTMLDivElement>(null);
    const [isVideoEnded, setIsVideoEnded] = useState(false);

    const [cookies, setCookie] = useCookies(["knownUser"]);

    useEffect(() => {
        const knownUser = cookies.knownUser;
        console.log(knownUser);

        if (knownUser == undefined || knownUser == false) {
            console.log("User visits first time");
            console.log("No Cookies Found");
            setCookie("knownUser", "true", { path: "/" });
            setIsFirstVisit(true);
        } else if (knownUser == true) {
            setIsFirstVisit(false);
            //localStorage.setItem("mbt_already_visited", "true");
            //setCookie("knownUser", "true", { path: "/" });
            console.log("User revisits");
        }
    }, []);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const videoUrl = isFirstVisit
        ? VIDEO_INTRO // Replace with your YouTube video ID for the first visit
        : VIDEO_REVISIT; // Replace with your YouTube video ID for subsequent visits

    const playerStyle = {
        transform: "translate(0, 0) scale(2.2)"
    };

    const startVideo = () => {

        if (playerRef.current) {
            // @ts-ignore
            playerRef.current.seekTo(0); // Optional: if you want to start from the beginning
            // @ts-ignore
            playerRef.current.getInternalPlayer().playVideo(); // Directly using the YouTube API
            setIsPlaying(true)
        }

    };

    const handleVideoStart = () => {
        setIsPlaying(true);
    }
    
    const handleVideoEnd = () => {
        setIsVideoEnded(true);
        setIsPlaying(false)
    };

    if((!SHOW_VIDEOS)) {
        return (<div ref={videoRootDiv} className={styles.videoRoot}><div className={styles.youtubedivstyle}></div></div>)
    }

    return (
        <div ref={videoRootDiv} className={`${styles.videoRoot} ${isVideoEnded ? styles.Gone : ""}`}>
            <div className={styles.youtubedivstyle}>
                {/* <ReactPlayer config={{ file: { forceHLS: true } }} url={`${videoUrl}`} playing muted={isMuted} controls width="75%" /> */}
                {/* <ReactPlayer config={{ file: { forceHLS: true } }} url={`${videoUrl}`} controls width="60%" height="60%" /> */}
                <ReactPlayer
                    // @ts-ignore
                    ref={playerRef}
                    url={`${videoUrl}`} // Replace [YourVideoID] with the actual YouTube video ID
                    playing={true} // Enables autoplay
                    muted={false}
                    controls // Shows the video controls
                    //width="100%"
                    //height="200%"
                    onEnded={handleVideoEnd}
                    onStart={handleVideoStart}
                    style={playerStyle}
                    config={{
                        youtube: {
                            playerVars: {
                                height: 100,
                                autoplay: 1,
                                controls: 0,
                                volume: 0.5,
                                muted: true,
                                disablekb: 1,
                                color: "white",
                                playing: true,
                                modestbranding: 1,
                                playsinline: 1,
                                showinfo: 0
                            } // YouTube player specific configurations
                        }
                    }}
                />
            </div>
            {isFirstVisit && (
                <div ref={exampleDivRef} className={`${styles.example} ${isVideoEnded ? styles.Gone : (isPlaying ? styles.Hidden : styles.Visible)}`} onClick={startVideo}>
                    <p className={styles.exampleText}>
                        <AirplaneTakeOff24Regular />
                        Starte Einführung
                    </p>
                </div>
            )}
            {!isFirstVisit && (
                <div ref={exampleDivRef} className={`${styles.example} ${isVideoEnded ? styles.Gone : (isPlaying ? styles.Hidden : styles.Visible)}`} onClick={startVideo}>
                    <p className={styles.exampleText}>
                        Willkommen zurück! <Play24Filled />
                    </p>
                </div>
            )}
            {isVideoEnded && <ChatAvatar isStreaming={false}></ChatAvatar>}
        </div>
    );
};

export default BackgroundVideo;
