const BACKEND_URI = "";

import { ADMIN_CLIENT_ID } from "./secret"
import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config } from "./models";
import { useLogin, appServicesToken } from "../authConfig";

function getHeaders(idToken: string | undefined, isAdmin: boolean | false, clientSecret: String | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (useLogin && appServicesToken == null) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }

    var clientId = ADMIN_CLIENT_ID;

    if(isAdmin) {
        headers["Authorization"] = "Basic " + btoa(`${clientId}:${clientSecret}`);
    }

    return headers;
}

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken, false, ""),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken, false, "")
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken, false, ""),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function getAuth(clientSecret: string | undefined, idToken: string | undefined, token : String): Promise<Response> {
    return await fetch(`${BACKEND_URI}/auth`, {
        method: "POST",
        headers: getHeaders(idToken, false, clientSecret),
        body: JSON.stringify(token)
    });
}

export async function getAdminAuth(clientSecret: string | undefined, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/admin/auth`, {
        method: "GET",
        headers: getHeaders(idToken, true, clientSecret)
    });
}

export async function getSystemPromptApi(clientSecret: string | undefined, systemPrompt: string, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/admin/get_system_prompt`, {
        method: "GET",
        headers: getHeaders(idToken, true, clientSecret)
        //body: JSON.stringify(systemPrompt)
    });
}

export async function updateSystemPromptApi(clientSecret: string | undefined, systemPrompt: string, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/admin/update_system_prompt`, {
        method: "POST",
        headers: getHeaders(idToken, true, clientSecret),
        body: JSON.stringify(systemPrompt)
    });
}

export async function getLogs(clientSecret: string | undefined, idToken: string | undefined, type : string): Promise<Response> {
    return await fetch(`${BACKEND_URI}/admin/downloadLogs/${type}`, {
        method: "GET",
        headers: getHeaders(idToken, true, clientSecret)
    });
}

export async function getLogsFromStartEnd(clientSecret: string | undefined, starttime: string, endtime: string, idToken: string | undefined): Promise<Response> {
    const query = `?starttime=${encodeURIComponent(starttime)}&endtime=${encodeURIComponent(endtime)}`;
    return await fetch(`${BACKEND_URI}/admin/downloadLogs/json${query}`, {
        method: "GET",
        headers: getHeaders(idToken, true, clientSecret)
    });
}

export async function like(uuid: string, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat/${uuid}/reaction/positive`, {
        method: "GET",
        headers: getHeaders(idToken, true, "")
    });
}

export async function dislike(uuid: string, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat/${uuid}/reaction/negative`, {
        method: "GET",
        headers: getHeaders(idToken, true, "")
    });
}
